.answers {
  background-color: dark-neutral(500);
  padding: 50px 0;
  &-list {
    display: flex;
    position: relative;
    z-index: 2;
    &-item {
      padding: 0 10px;
      button {
        background-color: light-neutral(0);
        border-radius: 30px;
        box-shadow: 0px 25px 65px -60px dark-neutral(0);
        padding: 25px;
        height: 100%;
        max-width: 165px;
        font-size: 16px;
        line-height: 16px;
        transition: all, 0.4s;
        color: dark-neutral(0);
        figure {
          margin: 0 0 15px 0;
        }
        &:hover {
          transition: all, 0.4s;
          figure {
            img {
              filter: brightness(0) invert(1);
            }
          }
        }
      }
      &--active {
        button {
          background-color: primary(300);
          color: light-neutral(0);
          figure {
            img {
              filter: brightness(0) invert(1);
            }
          }
        }
      }
    }
  }
  &-list-content {
    position: relative;
    margin-top: 30px;
    z-index: 1;
    .col {
      padding-top: 0;
      padding-bottom: 0;
    }
    &-item {
      background-color: light-neutral(0);
      border-radius: 30px;
      position: absolute;
      transition: all, 0.4s;
      .text-area {
        padding: 40px;
        .title {
          font-size: 25px;
          color: primary(300);
        }
        .more {
          padding: 0 15px;
          line-height: 30px;
          background-color: primary(300);
          color: light-neutral(0);
          display: inline-block;
          margin: 15px 0 0 0;
          border-radius: 25px;
          transition: all, 0.4s;
          &:hover {
            background-color: primary(100);
            transition: all, 0.4s;
          }
        }
      }
      &--disable {
        opacity: 0;
        top: -50px;
        transition: all, 0.4s;
        pointer-events: none;
      }
      &--active {
        opacity: 1;
        top: 0;
        transition: all, 0.4s;
        transition-delay: 0.4s;
        pointer-events: auto;
      }
    }
  }
}
@media #{$media-md} {
  .answers {
    &-list {
      &-content {
        &-item {
          .text-area {
            .title {
              font-size: 20px;
              font-weight: 600;
            }
          }
        }
      }
      flex-wrap: wrap;
      &-item {
        width: 25%;
        padding: 0 !important;
        margin-bottom: 10px;
        button {
          font-size: 10px;
          line-height: 14px;
          width: 100%;
        }
      }
    }
  }
}
@media #{$media-sm} {
  .answers {
    &-list {
      &-content {
        &-item {
          .text-area {
            .title {
              font-size: 20px;
              font-weight: 600;
            }
          }
        }
      }
      flex-wrap: wrap;
      &-item {
        width: 50%;
        padding: 0 !important;
        margin-bottom: 10px;
        button {
          font-size: 10px;
          line-height: 14px;
          width: 100%;
        }
      }
    }
  }
}
