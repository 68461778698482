.slider {
  b {
    color: primary(300);
  }
  position: relative;
  .slick-slider {
    .slick-arrow,
    .slick-dots {
      display: none !important;
    }
    .slick-list {
      .slick-track {
        .slick-slide {
          .item {
            height: 100vh;
            display: flex;
            align-items: center;
            text-align: center;
            color: light-neutral(0);
            position: relative;
            z-index: 1;
            &::before {
              content: '';
              width: 100%;
              height: 100%;
              position: absolute;
              background-color: primary(200);
              z-index: -1;
              opacity: 0.77;
            }
            .title {
              font-size: 50px;
              line-height: 50px;
              font-weight: bold;
              span {
                display: block;
                font-size: 70px;
                line-height: 70px;
              }
            }
            figure {
              .image {
                width: auto;
                height: 300px;
              }
            }
            .desc {
              p {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }
}

@media #{$media-md} {
  .slider {
    .image {
      .image-node {
        object-fit: contain;
      }
    }
    .slick-slider {
      .slick-list {
        .slick-track {
          .slick-slide {
            .item {
              figure {
                .image {
                  height: auto;
                }
              }
              .desc {
                p {
                  font-size: 12px;
                }
              }
              .title {
                font-size: 40px;
                line-height: 40px;
                span {
                  font-size: 30px;
                  line-height: 34px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media #{$media-sm} {
  .slider {
    .image {
      .image-node {
        object-fit: contain;
      }
    }
    .slick-slider {
      .slick-list {
        .slick-track {
          .slick-slide {
            .item {
              figure {
                .image {
                  height: auto;
                }
              }
              .desc {
                p {
                  font-size: 12px;
                }
              }
              .title {
                font-size: 14px;
                line-height: 20px;
                span {
                  font-size: 20px;
                  line-height: 34px;
                }
              }
            }
          }
        }
      }
    }
  }
}
