.price-method {
  padding: 50px 0;
  &-title {
    font-size: 20px;
    color: primary(300);
    font-weight: bold;
    margin-bottom: 25px;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      display: flex;
      margin-bottom: 15px;
      .image {
        margin-right: 15px;
      }
    }
  }
}
@media (max-width: 768px) {
  .price-method {
    &-title {
      text-align: center;
    }
  }
}
