.news {
  background-color: dark-neutral(300);
  padding: 25px 0;
  position: relative;
  .section {
    &-title {
      text-transform: uppercase;
      color: dark-neutral(30);
      margin-bottom: 15px;
    }
  }
  &-slide {
    width: 100%;
    .slick-list {
      .slick-slide {
        padding: 0 0 50px 0;
        box-sizing: border-box;
        > div {
          padding: 0 25px;
        }
      }
    }

    .item {
      &-in {
        background-color: light-neutral(0);
        border-radius: 45px;
        border: 1px solid primary(300);
        .image {
          width: 100%;
        }
        .desc {
          padding: 35px;
          text-align: center;
          .title {
            font-size: 20px;
            margin-bottom: 15px;
            color: primary(300);
            font-weight: bold;
          }
          .text {
            min-height: 115px;
          }
          .more {
            padding: 0 15px;
            background-color: primary(300);
            color: light-neutral(0);
            display: inline-block;
            position: relative;
            line-height: 50px;
            top: 50px;
            margin: 0 0 -50px 0;
            border-radius: 25px;
            transition: all, 0.4s;
            &:hover {
              background-color: primary(400);
              transition: all, 0.4s;
            }
          }
        }
      }
    }

    &-arrow {
      width: 70px;
      height: 70px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 100%;
      background-color: light-neutral(20);
      border: 3px solid primary(300);
      transform: scale(1);
      box-shadow: 0px 0px 15px 0 dark-neutral(70);
      transition: all, 0.4s;
      z-index: 3;
      cursor: pointer;
      .c-icon {
        font-size: 40px;
      }
      &--prev {
        left: 5%;
      }
      &--next {
        right: 5%;
      }
      &:hover {
        background-color: primary(300);
        color: light-neutral(0);
        transform: scale(1.2);
        transition: all, 0.4s;
      }
    }
  }
}

@media only screen and (max-width: 1700px) {
  .news-slide-arrow--prev {
    left: 1%;
  }
  .news-slide-arrow--next {
    right: 1%;
  }
}

@media only screen and (max-width: 1400px) {
  .news-slide-arrow--prev {
    left: 1%;
  }
  .news-slide-arrow--next {
    right: 1%;
  }
}

@media only screen and (max-width: 768px) {
  .news {
    &-slide {
      &-arrow {
        &--prev {
          left: 5%;
        }
        &--next {
          right: 5%;
        }
        width: 40px;
        height: 40px;
        .c-icon {
          font-size: 18px;
        }
      }
      padding: 0 50px;
      width: 100%;
      box-sizing: border-box;
      .item {
        &-in {
          .desc {
            .more {
              top: 25px;
              font-size: 12px;
            }
            padding: 15px;
            .title {
              font-size: 14px;
            }
            .text {
              font-size: 12px;
            }
          }
          .image {
            width: 100%;
          }
        }
      }
    }
  }
}
