.contact {
  b {
    color: primary(300);
  }
  &-title {
    text-align: center;
    font-size: 35px;
    color: dark-neutral(50);
    font-weight: 700;
    margin-bottom: 25px;
  }
  &-form {
    &-title {
      font-size: 25px;
      color: primary(300);
      font-weight: 700;
      margin-bottom: 15px;
    }
    div {
      position: relative;
      .error-message {
        background-color: red;
        color: #fff;
        font-size: 11px;
        padding: 0 15px;
        position: absolute;
        z-index: 1;
        top: -30px;
        right: 0;
        line-height: 30px;
        height: 0;
        overflow: hidden;
        transition: all, 0.4s;
        &--active {
          height: 30px;
          transition: all, 0.4s;
        }
      }
    }
    input,
    textarea {
      width: 100%;
      border: none;
      border-bottom: 1px solid dark-neutral(100);
      padding: 15px;
      margin: 0 0 15px 0;
      outline: none;
      box-sizing: border-box;
    }
    textarea {
      height: 160px;
      border: 1px solid dark-neutral(100);
      resize: none;
    }
    .submit {
      float: right;
    }
  }
  .alert-message {
    padding: 15px;
    background-color: primary(200);
    border-radius: 5px;
    color: #fff;
    opacity: 0;
    position: relative;
    top: -25px;
    transition: all, 0.4s;
    background-color: light-neutral(300);
    &--error {
      background-color: red;
    }
    &--success {
      background-color: green;
    }
    &--active {
      top: 0;
      opacity: 1;
      transition: all, 0.4s;
    }
  }
  &-social-media {
    color: primary(300);
  }
}
