.partners {
  padding: 25px 0;
  position: relative;
  .section {
    &-title {
      text-transform: uppercase;
      color: dark-neutral(30);
      margin-bottom: 25px;
    }
  }
  &-slide {
    width: 100%;
    &-arrow {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      border: none;
      position: absolute !important;
      top: 50%;
      transform: translateY(-50%);
    }
    .slick-list {
      .slick-track {
        display: flex;
        align-items: center;
      }
      .slick-slide {
        padding: 0 0 0 0;
        box-sizing: border-box;
        > div {
          padding: 0 25px;
        }
      }
    }
    &-arrow {
      width: 70px;
      height: 70px;
      position: absolute;
      top: 60%;
      transform: translateY(-50%);
      border-radius: 100%;
      background-color: light-neutral(20);
      border: 3px solid primary(300);
      transform: scale(1);
      box-shadow: 0px 0px 15px 0 dark-neutral(70);
      transition: all, 0.4s;
      z-index: 3;
      cursor: pointer;
      .c-icon {
        font-size: 40px;
      }
      &--prev {
        left: 5%;
      }
      &--next {
        right: 5%;
      }
      &:hover {
        background-color: primary(300);
        color: light-neutral(0);
        transform: scale(1.2);
        transition: all, 0.4s;
      }
    }
  }
}

@media #{$media-md} {
  .partners {
    &-slide {
      &-arrow {
        width: 40px;
        height: 40px;
        .c-icon {
          font-size: 18px;
        }
        &--prev {
          left: 2%;
        }
        &--next {
          right: 2%;
        }
      }
    }
  }
  .slidee {
    padding: 15px 4rem;
  }
}

@media #{$media-sm} {
  .partners {
    &-slide {
      &-arrow {
        width: 40px;
        height: 40px;
        .c-icon {
          font-size: 18px;
        }
        &--prev {
          left: 2%;
        }
        &--next {
          right: 2%;
        }
      }
    }
  }
  .slidee {
    padding: 15px 4rem;
  }
}
