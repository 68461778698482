.header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  font-size: 18px;
  box-sizing: border-box;
  &-body {
    padding: 15px 50px;
    &-logo {
      width: auto;
      display: inline-flex;
      vertical-align: middle;
      cursor: pointer;
      .image {
        img {
          max-width: fit-content;
          height: 85px;
          display: block;
        }
      }
    }

    &-desktop-menu {
      ul {
        display: flex;
        justify-content: center;
        padding: 0;
        margin: 0;
        li {
          list-style-type: none;
          padding: 0 10px;
          margin: 0;
          position: relative;
          a {
            background-color: light-neutral(0);
            line-height: 50px;
            display: block;
            padding: 0 15px;
            border-radius: 25px;
            font-weight: bold;
            color: primary(300);
            text-align: center;
            white-space: nowrap;
            min-width: 100px;
            position: relative;
            z-index: 1;
            .c-icon {
              transform: rotate(0deg);
              transition: all, 0.4s;
            }
          }
          ul {
            position: absolute;
            display: block;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 10px;
            background-color: rgba(dark-neutral-rgb(0), 0.2);
            backdrop-filter: blur(10px);
            overflow: hidden;
            top: 30px;
            opacity: 0;
            pointer-events: none;
            transition: all, 0.4s;
            li {
              a {
                background-color: transparent;
                color: light-neutral(0);
                line-height: 35px;
                font-size: 14px;
                border-bottom: 1px solid rgba(light-neutral-rgb(0), 0.2);
                border-radius: 0;
                font-weight: 400;
                -webkit-text-stroke: 0px light-neutral(0);
                transition: all, 0.4s;
                &:hover {
                  -webkit-text-stroke: 1px light-neutral(0);
                  transition: all, 0.4s;
                }
              }
              &:last-child {
                a {
                  border: none;
                }
              }
            }
          }
          &.active {
            a {
              .c-icon {
                transform: rotate(180deg);
                transition: all, 0.4s;
              }
            }
            ul {
              top: 60px;
              opacity: 1;
              pointer-events: auto;
              transition: all, 0.4s;
            }
          }
        }
      }
    }

    &-language-selection {
      position: relative;
      display: flex;
      margin-right: 100px;
      z-index: 3;
      &-list {
        display: flex;
        padding: 0;
        margin: 0;
        li {
          list-style-type: none;
          margin: 0;
          padding: 0 5px;
          border-right: 1px solid rgba(light-neutral-rgb(0), 0.5);
          button {
            color: light-neutral(0);
            border: 1px solid transparent;
            width: 50px;
            height: 50px;
            background-color: transparent;
            text-transform: uppercase;
            &:active {
              color: light-neutral(0);
            }
            &:focus {
              box-shadow: none !important;
            }
            &.current {
              background-color: rgba(light-neutral-rgb(0), 0.2) !important;
              border-color: light-neutral(0) !important;
              transition: all, 0.4s;
            }
            &:hover {
              background-color: rgba(light-neutral-rgb(0), 0.2) !important;
              border-color: light-neutral(0) !important;
              transition: all, 0.4s;
            }
          }
          &:last-child {
            border: none;
            button {
              border-color: transparent;
            }
          }
          &.active {
            border-right: 1px solid rgba(light-neutral-rgb(900), 0.5);
            button {
              color: light-neutral(900);
              &:active {
                color: light-neutral(900);
              }
              &.current {
                background-color: rgba(light-neutral-rgb(900), 0.2) !important;
                border-color: transparent;
              }
              &:hover {
                background-color: rgba(light-neutral-rgb(900), 0.2) !important;
                border-color: transparent;
                transition: all, 0.4s;
              }
            }
            &:last-child {
              border: none;
            }
          }
        }
        &-dropdown {
          position: absolute;
          background-color: rgba(light-neutral-rgb(900), 0.7);
          top: calc(100% + 15px);
          left: 50%;
          transform: translateX(-50%);
          border-radius: 15px;
          overflow: hidden;
          border: 1px solid light-neutral(800);
          &-list {
            padding: 0;
            margin: 0;
            li {
              border: none;
              border-bottom: 1px solid light-neutral(800);
              button {
                width: 100%;
                height: auto;
                color: light-neutral(0) !important;
              }
            }
          }
        }
      }
    }

    &-menu-button {
      position: relative;
      border: none;
      background: transparent !important;
      line-height: 40px;
      color: light-neutral(0);
      z-index: 3;
      cursor: pointer;
      &:active {
        color: light-neutral(0) !important;
      }
      &:focus {
        box-shadow: none !important;
      }
      &.active {
        color: light-neutral(0);
        &:active {
          color: light-neutral(0) !important;
        }
      }
    }

    &.reverse {
      .ggm-header-body-language-selection {
        &-list {
          li {
            border-right: 1px solid rgba(light-neutral-rgb(900), 0.5);
            button {
              color: light-neutral(900);
              &:active {
                color: light-neutral(900);
              }
              &.current {
                background-color: rgba(light-neutral-rgb(900), 0.2) !important;
                border-color: transparent;
              }
              &:hover {
                background-color: rgba(light-neutral-rgb(900), 0.2) !important;
                border-color: transparent;
              }
            }
          }
          &-dropdown {
            position: absolute;
            background-color: rgba(light-neutral-rgb(900), 0.7);
            top: calc(100% + 15px);
            left: 50%;
            transform: translateX(-50%);
            border-radius: 15px;
            overflow: hidden;
            border: 1px solid light-neutral(800);
            &-list {
              padding: 0;
              margin: 0;
              li {
                border: none;
                border-bottom: 1px solid light-neutral(800);
                button {
                  width: 100%;
                  height: auto;
                  color: light-neutral(0) !important;
                }
              }
            }
          }
        }
      }

      .ggm-header-body-menu-button {
        color: light-neutral(900);
        &:active {
          color: light-neutral(900) !important;
        }
        &:after {
          content: '';
          height: 1px;
          width: 100%;
          position: absolute;
          left: 0;
          bottom: 0;
          background-color: rgba(light-neutral-rgb(900), 0.5);
        }
        &.active {
          color: light-neutral(900);
          &:active {
            color: light-neutral(900) !important;
          }
          &::after {
            background-color: rgba(light-neutral-rgb(900), 0.5);
          }
        }
      }
    }
  }

  &--not-home {
    background-color: rgba(light-neutral-rgb(0), 0.2);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    .header-body {
      .header-body-menu-button {
        color: primary(200);
      }
    }
  }

  .navigation {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    text-align: right;

    &-back-button {
      -ievar-color: light-neutral(900);
      color: light-neutral(900);
      background-color: transparent;
      border: 0;
      outline: none;
      cursor: pointer;
      padding: 1rem 0;

      span:not(:first-child) {
        margin-left: 0.5em;
      }
    }

    &-header {
      margin-bottom: 50px;
      &-label {
        border-bottom: 1px solid dark-neutral(0);
      }
    }

    &-in {
      padding: 15px;
      padding-top: 110px;
      padding-right: 65px;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
    }

    &-content {
      width: 100%;
      .back-button {
        opacity: 0;
        position: relative;
        right: -25px;
        cursor: pointer;
        pointer-events: none;
        background: transparent;
        color: light-neutral(0);
        border-radius: 100%;
        width: 45px;
        height: 45px;
        border: none;
        transition: all, 0.2s;
        &:hover {
          background-color: #fff;
          color: primary(300);
        }
        &.active {
          right: 0;
          opacity: 1;
          pointer-events: auto;
          transition: all, 0.4s;
        }
      }
      nav {
        opacity: 0;
        right: -100%;
        transition: all, 0.4s;
        position: absolute;
        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;
          li {
            margin: 15px 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: relative;
            a {
              line-height: 50px;
              font-size: 25px;
              white-space: nowrap;
              transition: all, 0.1s;
              color: light-neutral(0);
              outline: none !important;
              font-weight: 600;
              display: block;
              position: relative;
              &.current {
                font-weight: bold;
                transition: all, 0.2s;
              }
              &:hover {
                font-weight: bold;
                transition: all, 0.2s;
              }
            }
            &::after {
              content: '';
              width: 45px;
              height: 2px;
              background-color: primary(200);
              position: absolute;
              bottom: 0;
              right: 0;
            }
          }
        }
        &.navigation-menu--main {
          right: 100%;
          &.navigation-menu--active {
            right: 65px;
          }
        }
        &.navigation-menu--active {
          right: 65px;
          opacity: 1;
        }
      }
    }

    &-footer {
      display: block;
      position: relative;
      align-self: flex-end;
      width: 100%;
      .title {
        font-size: 20px;
        font-weight: bold;
      }
      .social-media {
        margin-top: 25px;
      }
    }
  }

  .panel {
    position: absolute;
    top: 0;
    right: -25%;
    width: 25%;
    height: 100vh;
    background-color: rgba(dark-neutral-rgb(0), 0.4);
    backdrop-filter: blur(10px);
    z-index: 2;
    box-sizing: border-box;
    box-shadow: 1px 1px 30px -10px dark-neutral(0);
    overflow: hidden;
    transition: all, 0.5s;
    color: light-neutral(0);

    &--hide {
      width: 0;
      pointer-events: none;
    }

    &--show {
      right: 0;
      animation-name: openMenu;
      animation-duration: 1s;
    }

    &-top {
      text-align: right;
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      z-index: 1;
      &-close-button {
        position: relative;
        margin: 10px;
        color: dark-neutral(0);
        background-color: dark-neutral(0);
        border: 0;
        width: 25px;
        height: 25px;
        outline: none;
        cursor: pointer;
        border-radius: 5px;
        box-sizing: border-box;
        z-index: 100;
        span {
          font-size: 8px;
          line-height: 25px;
        }
        &:hover {
          background-color: dark-neutral(20);
        }
      }
    }

    &-body {
      height: 100%;
    }
  }
}

@keyframes openMenu {
  0% {
    width: 25%;
  }
  50% {
    width: 25.5%;
  }
  100% {
    width: 25%;
  }
}

@media #{$media-md} {
  .header {
    overflow-x: clip;
    &-body {
      padding: 15px;
    }
  }
  .panel {
    &--show {
      width: 100% !important;
    }
  }
  @keyframes openMenu {
    0% {
      width: 0% !important;
    }
    100% {
      width: 100% !important;
    }
  }
}

@media #{$media-sm} {
  .header {
    overflow-x: clip;
    &-body {
      padding: 15px;
    }
  }
  .panel {
    &--show {
      width: 100% !important;
    }
  }
  @keyframes openMenu {
    0% {
      width: 0% !important;
    }
    100% {
      width: 100% !important;
    }
  }
}
