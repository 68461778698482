.question-answer {
  hr {
    background-color: primary(300);
  }
  &-title {
    text-align: center;
    font-size: 35px;
    color: dark-neutral(50);
    font-weight: 700;
  }
  &-list {
    padding: 0 0 50px 0;
    &-item {
      .question,
      .answer {
        display: flex;
        height: max-content;
        position: relative;
        figure {
          display: flex;
          align-items: flex-end;
          .image {
            width: 75px;
            height: 75px;
            position: relative;
            overflow: hidden;
            border-radius: 100%;
            position: relative;
            top: 50px;
          }
        }
        &-desc {
          padding: 15px;
          box-sizing: border-box;
          border-radius: 15px;
          position: relative;
          z-index: 1;
        }
      }
      .question {
        figure {
          .image {
            margin-right: 15px;
          }
        }
        &-desc {
          background-color: primary(300);
          color: light-neutral(0);
          &::after {
            content: '';
            border-width: 20px;
            border-color: primary(300);
            border-style: solid;
            position: absolute;
            bottom: -20px;
            left: 0;
            border-right-color: transparent;
            border-bottom-color: transparent;
          }
        }
      }
      .answer {
        margin-top: 40px;
        figure {
          .image {
            margin-left: 15px;
            border: 1px solid primary(300);
            padding: 15px;
            box-sizing: border-box;
          }
        }
        &-desc {
          border: 1px solid primary(300);
          color: light-neutral(900);
          background-color: light-neutral(0);
          box-shadow: 1px 1px 1px 1px primary(300);
        }
        &::after {
          content: '';
          border-width: 30px;
          border-color: primary(300);
          border-style: solid;
          position: absolute;
          bottom: -20px;
          right: 105px;
          border-left-color: transparent;
          border-bottom-color: transparent;
          stroke: black;
          z-index: -1;
          transform: rotate(-25deg);
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .question-answer {
    &-title {
      font-size: 25px;
    }
    &-list {
      &-item {
        .answer {
          figure {
            .image {
              width: 50px;
              height: 50px;
            }
          }
        }
        .question {
          figure {
            .image {
              width: 50px;
              height: 50px;
            }
          }
        }
        .answer {
          &-desc {
            font-size: 12px;
          }
        }
        .question {
          &-desc {
            font-size: 12px;
          }
        }
      }
    }
  }
}
