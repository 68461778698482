.charge-station {
  position: relative;
  background-color: primary(300);
  padding: 50px 0 25px 0;
  color: light-neutral(0);
  .section-title {
    font-size: 60px;
    line-height: 60px;
    font-weight: 600;
    span {
      display: block;
      font-size: 70px;
      line-height: 70px;
      font-weight: 900;
    }
  }
  &:before {
    content: '';
    width: 100%;
    height: 50px;
    background-color: light-neutral(0);
    transform: skewY(-1deg);
    position: absolute;
    left: 0;
    top: -25px;
  }
  figure {
    .image {
      margin: 25px 0;
      width: max-content;
    }
  }
  .children {
    margin: 25px 0;
    p {
      font-size: 16px;
    }
  }
}
@media (max-width: 768px) {
  .charge-station {
    figure {
      .image {
        margin: 0;
      }
    }
    .children p {
      font-size: 11px;
    }
    .section-title {
      font-size: 16px;
      line-height: 20px;
      span {
        font-size: 20px;
        line-height: 34px;
      }
    }
  }
}
