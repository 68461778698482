.footer {
  padding: 25px 0;
  background-color: dark-neutral(100);
  &-top-wrapper {
    &-logo {
      margin-bottom: 15px;
      display: block;
    }
    &-social-media {
      display: flex;
      color: primary(300);
    }
    &-menu {
      ul {
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        li {
          list-style-type: none;
          margin: 0 0 15px 0;
          flex-basis: 33.33%;
          a {
            line-height: 35px;
            outline: none !important;
            -webkit-text-stroke: 0px light-neutral(900);
            font-size: 20px;
            color: primary(300);
            font-weight: bold;
            display: block;
            position: relative;
            margin-bottom: 10px;
            &::after {
              content: '';
              width: 50px;
              height: 2px;
              background-color: primary(300);
              position: absolute;
              left: 0;
              bottom: 0;
            }
          }
          ul {
            flex-direction: column;
            li {
              margin: 0;
              a {
                font-size: 14px;
                color: dark-neutral(0);
                line-height: 30px;
                font-weight: 400;
                margin: 0;
                &::after {
                  display: none;
                }
                -webkit-text-stroke: 0px light-neutral(900);
                &:hover {
                  -webkit-text-stroke: 1px light-neutral(900);
                }
              }
            }
          }
        }
      }
    }
    .title {
      font-size: 20px;
      color: primary(200);
      font-weight: bold;
      margin-bottom: 10px;
    }
    &-contacts {
      margin-bottom: 15px;
      span,
      a {
        display: block;
        margin: 5px 0;
      }
      a {
        -webkit-text-stroke: 0px light-neutral(900);
        &:hover {
          -webkit-text-stroke: 1px light-neutral(900);
        }
      }
    }
  }
  &-bottom-wrapper {
    border-top: 1px solid light-neutral(900);
    align-items: center;
    &-disclaimer {
      font-size: 12px;
    }
    &-copyright {
      span {
        white-space: nowrap;
        margin: 5px 0;
        font-size: 15px;
        display: block;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &-policy-menu {
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: flex-end;
        li {
          margin: 0 10px;
          a {
            white-space: nowrap;
            -webkit-text-stroke: 0px light-neutral(900);
            &:hover {
              -webkit-text-stroke: 1px light-neutral(900);
            }
          }
        }
      }
    }
  }
}

@media #{$media-sm} {
  .ggm-footer {
    &-top-wrapper {
      &-menu {
        a {
          flex-basis: 100%;
          font-size: 14px;
          line-height: 30px;
        }
      }
    }
    &-bottom-wrapper {
      &-copyright {
        justify-content: space-between;
        span {
          white-space: normal;
          font-size: 10px;
        }
      }
      &-disclaimer {
        text-align: center;
        font-size: 10px;
      }
      &-policy-menu {
        ul {
          justify-content: center;
          li {
            a {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .footer {
    &-top {
      &-wrapper {
        &-social-media {
          justify-content: center;
        }
        &-contacts {
          span {
            font-size: 12px;
          }
        }
        &-menu {
          ul > li {
            & > a {
              line-height: 25px;
              font-size: 16px;
            }
            & ul > li > a {
              font-size: 12px;
            }
          }
        }
        .title {
          font-size: 14px;
        }
      }
    }
    &-bottom {
      &-wrapper {
        text-align: center;
        &-policy {
          &-menu ul {
            justify-content: center !important;
          }
        }
      }
    }
  }
}
