.vheicles {
  padding: 80px 0;
  position: relative;
  z-index: 2;
  &:before {
    content: '';
    background-color: dark-neutral(300);
    height: 70%;
    width: 100%;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
  &.section {
    .section-title {
      font-size: 60px;
      line-height: 60px;
      font-weight: 600;
      color: primary(300);
      span {
        display: block;
        font-size: 70px;
        line-height: 70px;
        font-weight: 900;
      }
    }
    .desc {
      font-size: 20px;
      margin: 30px 0 0 0;
      color: dark-neutral(20);
    }
    .filter {
      display: flex;
      justify-content: center;
      position: relative;
      z-index: 3;
      .dropdown {
        position: relative;
        margin: 0 15px;
        button {
          line-height: 60px;
          border-radius: 15px;
          background-color: light-neutral(0);
          white-space: nowrap;
          padding: 0 25px;
          color: dark-neutral(30);
          position: relative;
          z-index: 2;
          box-shadow: 0px 20px 15px -25px dark-neutral(0);
          min-width: 300px;
          text-align: left;
          .button-text {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .c-icon {
              color: primary(300);
              margin-left: 25px;
              transform: rotate(0deg);
              transition: all, 0.4s;
            }
          }
        }
        &-menu {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          opacity: 0;
          top: 50px;
          background-color: rgba(dark-neutral-rgb(0), 0.4);
          backdrop-filter: blur(10px);
          border-radius: 15px;
          pointer-events: none;
          z-index: 1;
          transition: all, 0.4s;
          ul {
            padding: 0;
            margin: 0;
            list-style-type: none;
            li {
              button {
                width: 100%;
                background: transparent;
                border: none;
                padding: 0 25px;
                line-height: 30px;
                color: light-neutral(0);
                font-size: 13px;
                box-shadow: none !important;
                border-radius: 0;
                &:hover {
                  background-color: rgba(light-neutral-rgb(0), 0.1);
                }
              }
            }
          }
        }
        &--active {
          button {
            .button-text {
              .c-icon {
                transform: rotate(180deg);
                transition: all, 0.4s;
              }
            }
          }
          .dropdown-menu {
            opacity: 1;
            top: 75px;
            transition: all, 0.4s;
            pointer-events: auto;
          }
        }
      }
    }
  }
  &-item {
    text-align: center;
    position: relative;
    &-head {
      > figure {
        position: relative;
        z-index: 1;
        .image {
          height: 200px;
        }
      }
      .vheicles-item-brand {
        font-size: 25px;
        font-weight: 600;
        position: relative;
        z-index: 1;
      }
      .vheicles-item-model {
        font-size: 25px;
        font-weight: 400;
        position: relative;
        z-index: 1;
      }
    }
    &-types {
      display: flex;
      justify-content: center;
      padding: 25px 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      border-radius: 30px;
      border: 1px solid dark-neutral(100);
      left: 0;
      top: 0;
      background-color: light-neutral(20);
      box-shadow: 0px 25px 65px -60px dark-neutral(0);
      box-shadow: inset 0px 25px 65px -60px dark-neutral(50);
      transition: all, 0.4s;
      width: 100%;
      padding-top: 300px;
      button {
        position: absolute;
        top: 25px;
        right: 25px;
        z-index: 3;
        background: transparent;
        border: none !important;
        box-shadow: none !important;
        padding: 0;
        margin: 0;
        line-height: normal;
        min-height: fit-content;
        min-width: max-content;
        opacity: 0;
        pointer-events: none;
        transition: all, 0.4s;
        span {
          & > span {
            width: 25px;
            height: 25px;
            text-align: center;
            line-height: 25px;
          }
        }
      }
      .type {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-basis: 1%;
        padding: 0 10px;
        .head {
          border-radius: 15px;
          background-color: light-neutral(0);
          width: 55px;
          font-size: 14px;
          padding: 10px 0;
          margin-bottom: -15px;
          position: relative;
          z-index: 2;
          figure {
            .image {
              width: 10px;
            }
          }
        }
        .properties {
          border-radius: 25px;
          background-color: light-neutral(0);
          padding: 25px 15px;
          position: relative;
          z-index: 1;
          box-shadow: 0px 0 65px -50px dark-neutral(0);
          .property {
            margin: 5px 0;
            .title {
              font-size: 11px;
              white-space: nowrap;
            }
            .value {
              font-size: 19px;
              color: #0089ff;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  &-item--active {
    button {
      opacity: 1;
      pointer-events: auto;
      transition: all, 0.4s;
    }
    .vheicles-item-types {
      opacity: 1;
      pointer-events: auto;
      transition: all, 0.4s;
      button {
        color: light-neutral(0);
        background-color: primary(300);
        border-radius: 100%;
        .c-icon {
          font-size: 14px;
        }
      }
    }
    &::before {
      opacity: 1;
      pointer-events: auto;
      transition: all, 0.4s;
    }
  }
  &-slide {
    position: relative;
    .slick-slider {
      .slick-list {
        overflow: inherit;
        overflow-x: clip;
      }
    }
    &-arrow {
      width: 70px;
      height: 70px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 100%;
      background-color: light-neutral(20);
      border: 3px solid primary(300);
      transform: scale(1);
      box-shadow: 0px 0px 15px 0 dark-neutral(200);
      transition: all, 0.4s;
      cursor: pointer;
      .c-icon {
        font-size: 40px;
      }
      &--prev {
        left: -100px;
      }
      &--next {
        right: -100px;
      }
      &:hover {
        background-color: primary(300);
        color: light-neutral(0);
        transform: scale(1.2);
        transition: all, 0.4s;
      }
    }
  }
}

@media #{$media-sm} {
}
@media (max-width: 768px) {
  html {
    overflow-x: hidden !important;
  }
  .vheicles {
    padding: 25px 0;
    &-item {
      &-types {
        .type {
          .properties {
            .property {
              .title {
                font-size: 16px;
              }
            }
          }
        }
      }
      &-head {
        figure img {
          object-fit: contain;
          width: 100%;
        }
      }
    }
    &.section {
      .filter {
        flex-direction: column;
        gap: 1rem;
        .dropdown {
          button {
            z-index: 0;
          }
          &-menu ul {
            max-height: 200px;
            overflow-y: scroll;
          }
        }
      }
      .section-title {
        font-size: 14px;
        line-height: 20px;
        span {
          font-size: 18px;
          line-height: 20px;
        }
      }
      .desc {
        font-size: 12px;
      }
    }
  }
}
