.operation-repair {
  background-color: light-neutral(60);
  padding: 25px 0;
  position: relative;
  &-title {
    font-size: 35px;
    color: primary(300);
    font-weight: bold;
  }
  .absolute-image {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 30%;
  }
}
@media (max-width: 768px) {
  .repairItemColumn {
    h3 {
      font-size: 14px;
    }
    display: flex;
    flex-direction: column;
    font-size: 12px;
  }
  .operation-repair {
    &-title {
      text-align: center;
      font-size: 18px;
    }
    .absolute-image {
      position: relative;
      width: 90%;
    }
  }
}
