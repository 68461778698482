.section {
  width: 100%;
  z-index: 1;
  position: relative;
  padding: 50px 0;
  &-title {
    font-size: 40px;
    font-weight: 600;
  }
}

@media #{$media-sm} {
  .section-header {
    padding-bottom: 0.5rem;
    margin-bottom: 1.5rem;

    a {
      margin-top: 0.8rem;
      font-size: 0.8rem;
    }
  }
}
@media only screen and (max-width: 768px) {
  .section {
    &-title {
      font-size: 16px;
    }
  }
}
