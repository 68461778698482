.price {
  background-color: dark-neutral(300);
  &.section {
    padding: 50px 0 60px 0;
    .section-title {
      color: primary(300);
      margin-bottom: 15px;
    }
  }
  &-list {
    display: flex;
    justify-content: center;
    // column-gap: 12px;
    &-item {
      display: flex;
      border-radius: 50px;
      min-width: 100%;
      overflow: hidden;
      background-color: light-neutral(0);
      box-shadow: 0px 25px 65px -60px dark-neutral(0);
      .desc {
        display: flex;
        align-items: center;
        padding: 40px 20px 40px 30px;
        figure {
          margin-right: 20px;
          .image {
            width: 55px;
          }
        }
        .title {
          font-size: 32px;
          font-weight: 700;
          line-height: 40px;
          span {
            display: block;
            font-size: 20px;
            color: primary(300);
            font-weight: 400;
            line-height: 20px;
          }
        }
      }
      .price {
        background-color: primary(300);
        border-radius: 50px;
        color: light-neutral(0);
        min-width: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        .price-d {
          font-size: 30px;
          line-height: 30px;
          font-weight: 600;
          text-align: center;
          margin: 12px;
          span {
            display: block;
            font-size: 20px;
            line-height: 20px;
            font-weight: 400;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}

@media #{$media-2xl} {
  .price {
    &-list {
      &-item {
        .desc {
          padding: 40px 20px 40px 20px;
          .title {
            font-size: 28px;
            font-weight: 700;
            line-height: 40px;
          }
        }

        .price {
          .price-d {
            margin: 4px;
          }
        }
      }
    }
  }
}

@media #{$media-xl} {
  .price {
    &-list {
      &-item {
        .desc {
          padding: 40px 40px 40px 40px;
        }

        .price {
          min-width: 240px;
          .price-d {
            margin: 12px;
          }
        }
      }
    }
  }
}

@media #{$media-lg} {
  .price {
    &-list {
      &-item {
        .desc {
          padding: 40px 40px 40px 80px;
          .title {
            font-size: 34px;
          }
        }

        .price {
          min-width: 340px;
          .price-d {
            margin: 12px;
          }
        }
      }
    }
  }
}

@media #{$media-md} {
  .price {
    &-list {
      flex-direction: column;
      gap: 2rem;
      &-item {
        margin: 0 !important;
        display: flex;
        justify-content: space-between;
        .price {
          padding: 15px 25px;
          min-width: 240px;
          .price-d {
            span {
              font-size: 24px;
            }
          }
        }
        .desc {
          padding: 30px 40px;
        }
      }
    }
  }
}

@media #{$media-sm} {
  .price {
    &-list {
      flex-direction: column;
      padding: 8px 8px;
      &-item {
        margin: 0 !important;
        display: flex;
        justify-content: space-between;
        .price {
          padding: 12px;
          min-width: 110px;
          .price-d {
            margin: 4px;
            span {
              font-size: 16px;
            }
          }
        }
        .desc {
          padding: 20px 12px 20px 24px;
          .title {
            font-size: 20px;
            line-height: 24px;
            span {
              font-size: 16px;
              margin-top: 4px;
            }
          }

          .image {
            max-width: 40px;
          }
        }
      }
    }
  }
}
