.scroll-down {
  position: absolute;
  left: 50%;
  bottom: 35px;
  transform: translateX(-50%);
  border-radius: 100%;
  text-align: center;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: #fff;
  animation-name: down-up;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  color: dark-neutral(0);
  .c-icon {
    transform: rotate(90deg);
  }
}

@keyframes down-up {
  0% {
    bottom: 35px;
  }
  50% {
    bottom: 50px;
  }
  100% {
    bottom: 35px;
  }
}
