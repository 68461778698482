.software-information {
  padding: 50px 0;
  &-title {
    text-align: center;
    font-size: 35px;
    color: primary(300);
    font-weight: bold;
    margin-bottom: 25px;
  }
}
