.services-repair {
  .items {
    display: flex;
    margin: 25px 0 0 0;
    .item {
      text-align: center;
      width: 33.33%;
      figure {
        margin-bottom: 15px;
        .image {
          height: 50px;
        }
      }
      .title {
        font-size: 13px;
      }
    }
  }
}
@media (max-width: 768px) {
  .services-repair {
    .items {
      padding-bottom: 10rem;
    }
  }
}
