.charge-units {
  padding: 50px 0;
  &-title {
    text-align: center;
    font-size: 35px;
    color: primary(300);
    font-weight: bold;
    margin-bottom: 25px;
  }
  &-desc {
    text-align: center;
    .title {
      display: block;
      margin: 20px 0;
      font-size: 20px;
      font-weight: 600;
      color: primary(300);
    }
  }
  &-items {
    .item {
      .in {
        text-align: center;
        figure {
          border-top-left-radius: 100%;
          border-top-right-radius: 100%;
          box-shadow: 0px -5px 16px -13px dark-neutral(0);
          width: max-content;
          margin: 0 auto;
          padding: 20px;
          position: relative;
          z-index: 2;
          background-color: light-neutral(0);
          margin-bottom: -25px;
          img {
            width: 50px;
          }
        }
        .desc {
          position: relative;
          z-index: 1;
          padding: 25px;
          border-radius: 25px;
          box-shadow: 0px 0 15px -10px dark-neutral(0);
          min-height: 200px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: center;
          .title {
            color: primary(300);
            font-size: 18px;
            font-weight: 600;
          }
        }
      }
    }
  }
}
