.location-partners {
  background-color: light-neutral(60);
  padding: 50px 0;
  &-title {
    text-align: center;
    font-size: 35px;
    color: primary(300);
    font-weight: bold;
    margin-bottom: 25px;
  }
  .answers-area {
    .answer {
      position: relative;
      text-align: center;
      figure {
        .image {
          height: 50px;
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 40px;
            min-width: auto;
            min-height: auto;
          }
        }
      }
      .title {
        font-size: 12px;
      }
      &::after {
        content: '';
        height: 15px;
        width: 1px;
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
        background-color: primary(300);
      }
      &:nth-child(4n),
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
}
