.charge-locations {
  height: 100vh;
  overflow: hidden;
  .col {
    padding: 0;
  }
  &-sidebar {
    position: relative;
    height: 100vh;
    box-sizing: border-box;
    z-index: 2;
    box-shadow: 0 0 50px -40px dark-neutral(0);
    .charge-locations-head {
      padding-top: 110px;
      box-sizing: border-box;
      background-color: light-neutral(0);
      .chrage-locations-filter {
        padding: 25px;
        .field {
          padding: 15px 0;
          display: flex;
          justify-content: space-between;
          width: 100%;
          box-sizing: border-box;
          input {
            width: 100%;
            padding: 25px;
            box-sizing: border-box;
            border-radius: 35px;
            border: 1px solid light-neutral(50) !important;
            box-shadow: 0 0 25px -17px primary(300) !important;
            transition: all, 0.4s;
            outline: none;
            &:active,
            &:focus,
            &:hover {
              border: 1px solid light-neutral(50) !important;
              box-shadow: 0 0 25px -5px primary(300) !important;
              transition: all, 0.4s;
            }
          }
          .button {
            background: light-neutral(70);
            width: max-content;
            border-radius: 25px;
            margin: 0 10px;
            padding: 0 15px;
            box-shadow: none !important;
            color: dark-neutral(0);
            .button-text {
              display: flex;
              align-items: center;
              line-height: 50px;
              figure {
                width: 20px;
                height: 20px;
                margin-right: 7.5px;
                .image {
                  width: inherit;
                  height: inherit;
                }
              }
            }
            &:first-child {
              margin-left: 0;
            }
            &:last-child {
              margin-right: 0;
            }
            &.button--ac {
              &:hover,
              &.button--active {
                background: #0089ff;
                .button-text {
                  color: #fff;
                  .image {
                    img {
                      filter: brightness(0) invert(1);
                    }
                  }
                }
              }
            }
            &.button--dc {
              &:hover,
              &.button--active {
                background: #ff9d00;
                .button-text {
                  color: #fff;
                  .image {
                    img {
                      filter: brightness(0) invert(1);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .charge-locations-list {
      background-color: dark-neutral(300);
      padding: 25px;
      box-sizing: border-box;
      .in {
        height: 100%;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background: dark-neutral(300);
        }

        &::-webkit-scrollbar-thumb {
          background-color: dark-neutral(90);
          border-radius: 10px;
          border: 0px solid #ffffff;
        }
      }
      button {
        background: transparent;
        box-shadow: none !important;
        width: 95%;
        border-radius: 15px;
        padding: 15px;
        border: 2px solid transparent;
        transition: all, 0.4s;
        margin-bottom: 15px;
        color: dark-neutral(0) !important;
        .button-text {
          display: flex;
          align-items: center;
          figure {
            width: 50px !important;
            height: 50px !important;
            min-width: 50px !important;
            min-height: 50px !important;
            border-radius: 15px;
            background: #fff;
            margin-right: 15px;
            box-shadow: 1.5px 1.5px 10px -8px dark-neutral(0);
            display: flex;
            justify-content: center;
            align-items: center;
            .image {
              width: 30px;
              height: 30px;
            }
          }
          .desc {
            font-size: 18px;
            line-height: 20px;
            text-align: left;
            font-weight: bold;
            span {
              display: block;
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
        &.button--ac {
          &:hover,
          &.button--active {
            border-color: #0089ff;
            background: light-neutral(0);
            color: dark-neutral(0);
            transition: all, 0.4s;
            figure {
              background-color: #0089ff;
              transition: all, 0.4s;
              .image {
                img {
                  filter: brightness(0) invert(1);
                  transition: all, 0.4s;
                }
              }
            }
          }
        }
        &.button--dc {
          &:hover,
          &.button--active {
            border-color: #ff9d00;
            background: light-neutral(0);
            color: dark-neutral(0);
            transition: all, 0.4s;
            figure {
              background-color: #ff9d00;
              transition: all, 0.4s;
              .image {
                img {
                  filter: brightness(0) invert(1);
                  transition: all, 0.4s;
                }
              }
            }
          }
        }
      }
    }
    .charge-locations-selected {
      position: absolute;
      width: 350px;
      height: 100%;
      z-index: -1;
      top: 0;
      right: 0;
      background-color: light-neutral(50);
      box-shadow: inset 20px 0 30px -45px dark-neutral(0),
        0 0 50px -40px dark-neutral(0);
      transition: all, 0.4s;
      padding-top: 110px;
      & > div {
        padding: 50px;
        .type {
          padding: 5px;
          background-color: #fff;
          display: flex;
          align-items: center;
          border-radius: 25px;
          width: max-content;
          padding-right: 15px;
          figure {
            width: 30px;
            height: 30px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            .image {
              width: 15px;
              height: 15px;
            }
          }
          &--ac {
            figure {
              background-color: #0089ff;
              .image {
                img {
                  filter: brightness(0) invert(1);
                  transition: all, 0.4s;
                }
              }
            }
          }
          &--dc {
            figure {
              background-color: #ff9d00;
              .image {
                img {
                  filter: brightness(0) invert(1);
                  transition: all, 0.4s;
                }
              }
            }
          }
        }
        .title {
          font-size: 20px;
          font-weight: bold;
          margin: 15px 0;
        }
        p {
          font-size: 13px;
        }
        .handle-button {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          background-color: primary(200);
          color: light-neutral(0);
          height: 75px;
          border-top-left-radius: 25px;
          border-bottom-left-radius: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          margin: 0;
          outline: none;
          box-shadow: none;
          width: 34px;
          &:hover {
            background-color: primary(400);
          }
          .button-text {
            .c-icon {
              transform: rotate(0deg);
              transition: all, 0.4s;
            }
          }
          &--disable {
            right: -34px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 25px;
            border-bottom-right-radius: 25px;
            transition: all, 0.4s;
            .button-text {
              .c-icon {
                transform: rotate(180deg);
                transition: all, 0.4s;
              }
            }
          }
        }
      }
      &.charge-locations-selected--active {
        right: -350px;
        transition: all, 0.4s;
      }
    }
  }
  &-title {
    font-size: 40px;
    line-height: 40px;
    color: primary(300);
    font-weight: 600;
    margin: 25px 25px 0 25px;
  }
}

@media #{$media-md} {
  .energy-types div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex-direction: row;
  }
  .charge-locations {
    height: auto;
    &-sidebar {
      height: auto;
      overflow: hidden;
      .charge-locations {
        &-list {
          height: 350px !important;
        }
        &-head {
          padding-top: 150px;
          .chrage-locations-filter {
            .field {
              .button {
                margin: 0;
                .button-text {
                  font-size: 11px;
                  line-height: 15px;
                  padding: 10px 0;
                }
              }
            }
          }
        }
      }
    }
    &-title {
      font-size: 20px;
      line-height: 10px;
      text-align: center;
    }
  }
}

@media #{$media-sm} {
  .energy-types div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex-direction: row;
  }
  .charge-locations {
    height: auto;
    &-sidebar {
      height: auto;
      overflow: hidden;
      .charge-locations {
        &-list {
          height: 350px !important;
        }
        &-head {
          .chrage-locations-filter {
            .field {
              .button {
                margin: 0;
                .button-text {
                  font-size: 11px;
                  line-height: 15px;
                  padding: 10px 0;
                }
              }
            }
          }
        }
      }
    }
    &-title {
      font-size: 20px;
      line-height: 10px;
      text-align: center;
    }
  }
}
