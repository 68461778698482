.offer {
  width: 190px;
  position: fixed;
  bottom: -125px;
  right: -50px;
  z-index: 4;
  border: 2px solid primary(200);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: light-neutral(0);
  color: primary(300);
  padding: 60px 15px;
  box-sizing: border-box;
  opacity: 0;
  transform: scale(0);
  transition: all, 0.4s;
  &--active {
    opacity: 1;
    transform: scale(1);
    right: 50px;
    bottom: 100px;
    transition: all, 0.4s;
  }
  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: transparent;
    color: primary(300);
    padding: 0;
    margin: 0;
    min-width: auto;
    min-height: auto;
    line-height: normal;
    width: 30px;
    height: 30px;
  }
  .title {
    font-size: 15px;
    span {
      display: block;
      font-size: 19px;
      font-weight: 600;
    }
  }
  .discount {
    margin: 15px 0;
    .count {
      font-size: 120px;
      line-height: 90px;
      font-weight: bold;
      position: relative;
      width: max-content;
      letter-spacing: -10px;
      margin: 0 auto;
      &::before {
        content: '';
        position: absolute;
        top: 25px;
        left: -15px;
        font-size: 20px;
        width: 30px;
        height: 30px;
        line-height: 30px;
      }
    }
    span {
      display: block;
      font-size: 30px;
      font-weight: 400;
    }
  }
  .btn {
    width: 100px;
    line-height: 30px;
    background-color: primary(300);
    color: light-neutral(0);
    border-radius: 25px;
    transition: all, 0.4s;
    align-self: center;
    &:hover {
      background-color: primary(100);
      transition: all, 0.4s;
    }
  }
}

.contact-offer {
  position: fixed;
  background-color: primary(300);
  border: 2px solid light-neutral(0);
  z-index: 5;
  bottom: 75px;
  right: 50px;
  width: 75px;
  height: 75px;
  border-radius: 100%;
}
@media (max-width: 768px) {
  .contact {
    &-offer {
      bottom: 25px !important;
      right: 15px !important;
    }
  }
}
