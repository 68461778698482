.mobile-app {
  position: relative;
  z-index: 1;
  .section {
    padding: 0;
  }
  .text-area {
    .title {
      font-size: 40px;
      color: primary(300);
      font-weight: 600;
      word-spacing: 9999px;
      line-height: 40px;
      margin-bottom: 25px;
    }
    p {
      font-size: 20px;
    }
    .platforms {
      display: flex;
      margin-top: 50px;
      .item {
        margin: 0 15px;
        figure {
          .image {
            width: 250px;
          }
        }
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

@media #{$media-sm} {
  .ggm-about-us {
    padding: 0 15px;
    .title {
      font-size: 15px;
      line-height: 30px;
    }
  }
}
@media (max-width: 768px) {
  .mobile-app {
    text-align: center;
    .text-area {
      .platforms {
        .item {
          figure {
            .image {
              width: 100%;
            }
          }
        }
      }
      p {
        font-size: 14px;
      }
      .title {
        line-height: 24px;
        margin: 0 0 15px 0;
        font-size: 18px;
      }
    }
  }
}
