.detail-page {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: primary(300);
  }
}

@media #{$media-sm} {
}
