.banner {
  position: relative;
  height: 100vh;
  box-sizing: border-box;
  background-size: cover !important;
  background-position: center center;
  .parallax {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    .title {
      color: #fff;
      font-size: 150px;
      text-shadow: -1px -1px 0 primary(300), 1px -1px 0 primary(300),
        -1px 1px 0 primary(300), 1px 1px 0 primary(300);
      font-weight: 600;
      position: relative;
      width: 50%;
      text-align: center;
      top: 150px;
      line-height: 150px;
      span {
        display: block;
        font-size: 40px;
        font-weight: 700;
      }
    }
    .parallax-layer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      .image {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        img {
          min-width: auto;
        }
      }
    }
  }
  &--static {
    .parallax {
      .title {
        font-size: 90px;
        font-weight: 900;
        text-shadow: 1px 5px 10px dark-neutral(0);
        line-height: 100px;
      }
    }
  }
}

@media #{$media-md} {
  .banner {
    .parallax {
      .title {
        font-size: 120px;
        width: 100%;
        span {
          font-size: 40px;
        }
      }
    }
  }
}

@media #{$media-sm} {
  .banner {
    .parallax {
      .parallax-layer {
        .image {
          bottom: 230px;
        }
      }
      .title {
        font-size: 38px;
        width: 100%;
        span {
          font-size: 32px;
        }
      }
    }
  }
}
// color: #fff;
// font-size: 42px;
// text-shadow: -1px -1px 0 var(--PC300), 1px -1px 0 var(--PC300),
//   -1px 1px 0 var(--PC300), 1px 1px 0 var(--PC300);
// font-weight: 600;
// position: relative;
// width: 100%;
// text-align: center;
// top: 150px;
// line-height: 150px;
