.products {
  position: relative;
  height: 100vh;
  box-sizing: border-box;
  background-size: cover !important;
  background-position: center center;
  box-shadow: 0px 350px 550px 5px black inset;

  &-parallax {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
  }

  &-layer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 100px 50px 150px 50px;
    box-sizing: border-box;
    .image {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      img {
        min-width: auto;
      }
    }
  }

  &-title {
    height: fit-content;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    color: #000;
    font-size: 150px;
    text-shadow: -1px -1px 0 light-neutral(0), 1px -1px 0 light-neutral(0),
      -1px 1px 0 light-neutral(0), 1px 1px light-neutral(0);
    font-weight: 700;
    position: relative;
    width: 50%;
    text-align: center;
    top: 150px;
    line-height: 150px;
  }
  &--static {
    .products-parallax {
      .products-title {
        font-size: 90px;
        font-weight: 900;
        text-shadow: 1px 5px 10px dark-neutral(0);
        line-height: 100px;
      }
    }
  }
  &-description {
    color: #fff;
    text-shadow: 2px 2px 4px #000;
    span {
      font-size: 60px;
      font-weight: 500;
    }
    &-head {
      &-title {
        font-size: 55px;
        font-weight: 700;
        margin-bottom: 2rem;
        line-height: 55px;
        span {
          font-weight: 400;
          display: block;
        }
        &::before {
          content: '';
          position: relative;
          width: 15px;
          height: 50px;
          background-image: url('https://minio.wapbukafamedya.com/storage/Root/evc-charge/Path%201493.png');
          background-size: cover;
          background-repeat: no-repeat;
          display: block;
          margin-bottom: 15px;
        }
      }
    }
    &-text {
      font-size: 18px;
      p {
        margin: 0;
      }
    }
  }
}

@media #{$media-md} {
  .products {
    &-parallax {
      .products-title {
        font-size: 38px;
        width: 100%;
        span {
          font-size: 32px;
        }
      }
    }
    &-description {
      &-head {
        &-title {
          font-size: 25px;
          line-height: 30px;
          span {
            font-size: 25px;
          }
        }
      }
      &-text {
        font-size: 14px;
      }
    }
  }
}

@media #{$media-sm} {
  .products {
    &-parallax {
      .products-title {
        font-size: 38px;
        width: 100%;
        span {
          font-size: 32px;
        }
      }
    }
    &-description {
      &-head {
        &-title {
          font-size: 25px;
          line-height: 30px;
          span {
            font-size: 25px;
          }
        }
      }
      &-text {
        font-size: 14px;
      }
    }
  }
}
