.news-list {
  &-item {
    padding: 25px 0;
    border-bottom: 1px solid light-neutral(80);
    figure {
      .image {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
    .text-area {
      .title {
        font-size: 20px;
        color: primary(200);
        font-weight: bold;
        margin-bottom: 15px;
      }
    }
    .read-more {
      background-color: primary(300);
      color: light-neutral(0);
      border-radius: 25px;
      display: inline-block;
      padding: 0 25px;
      line-height: 50px;
      margin-top: 25px;
    }
    &:last-child {
      border: none;
    }
  }
  .pagination {
    display: flex;
    li {
      button {
        font-weight: bold;
        background-color: primary(100);
        border-radius: 5px;
      }
    }
  }
}
@media (max-width: 768px) {
  .news-list {
    padding: 0 1rem;
    &-item {
      figure {
        .image {
          img {
            padding-right: 2rem;
          }
        }
      }
    }
    #newsPdng {
      padding: 2rem 4rem 2rem 2rem;
      text-align: center;
    }
  }
}
